ul.bundleVasList {
	display: flex;
	flex-wrap: wrap;
	padding: 0px;

	& > li {
		width: 38%;
	    padding: 2%;
	    margin: 15px 10px 15px 10px;
	    list-style-type: none;
	    box-shadow: 0 1px 5px 0 rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.12);
	    border-radius: 2px;
	}

	.fpop-media-layout-sm & > li, .fpop-media-layout-xs & > li {
		max-height: inherit;
		width: 90%;
		display: block;
		float: none;
	}

	li {
		.featureDescription {
			display: inline-block;

			h4 {
				margin: 0px;
			}

			p {
				margin: 5px 0px 0px 0px;
				font-size: 14px;
			}
		}

		.featureList{
			display: inline-block;

			ul li {
				list-style-type: disc;
				font-size: 12px;
				letter-spacing: .02em;
				margin: 2px 0px;
			}
		}
	}

	li .featureDescription:before {
		content: '';
	    width: 100px;
	    height: 100px;
	    zoom:0.7;
   		-moz-transform:scale(0.7);
    	-moz-transform-origin: 0 0;
	    display: inline;
	    float: left;
	    margin: 12px;
	}

	li.premierVoicemail .featureDescription:before {
		background:url( 'https://www.freedompop.com/static/img/products/icon-cell-tower.png' ) no-repeat;
	}

	li.premierRollover .featureDescription:before {
		background:url( 'https://www.freedompop.com/static/img/products/icon-rollover.png' ) no-repeat;
	}

	li.premierSpeedPlus .featureDescription:before {
		background:url( 'https://www.freedompop.com/static/img/products/icon-speed-plus.png' ) no-repeat;
	}

	li.premierPayAsYouGo .featureDescription:before {
		background:url( 'https://www.freedompop.com/static/img/products/icon-payasyougo.png' ) no-repeat;
	}

	li.premierAlerts .featureDescription:before {
		background:url( 'https://www.freedompop.com/static/img/products/icon-alerts.png' ) no-repeat;
	}

	li.premierTether .featureDescription:before {
		background:url( 'https://www.freedompop.com/static/img/products/icon-tethering.png' ) no-repeat;
	}

	li.premierPremiumVoice .featureDescription:before {
		background:url( 'https://www.freedompop.com/static/img/products/icon-tethering.png' ) no-repeat;
	}

	li.premierMMS .featureDescription:before {
		background:url( 'https://www.freedompop.com/static/img/products/icon-mms.png' ) no-repeat;
	}

	li.premierCompression .featureDescription:before {
		background:url( 'https://www.freedompop.com/static/img/products/icon-compression.png' ) no-repeat;
	}

	li.premierSupport .featureDescription:before {
		background:url( 'https://www.freedompop.com/static/img/products/icon-badge-protectionplus.png' ) no-repeat;
	}

	li.premierSecurity .featureDescription:before {
		background:url( 'https://www.freedompop.com/static/img/products/icon-badge-secure.png' ) no-repeat;
	}

	li.premierPrivateInternet .featureDescription:before {
		background:url( 'https://www.freedompop.com/static/img/products/icon-private-internet.png' ) no-repeat;
	}


}